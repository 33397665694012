#main {
  overflow: hidden
}

#main-content {
  width: calc(100% - 260px);
  overflow: auto;
  height: 100%;
  margin: 20px;
  padding-right: 40px;
  padding-bottom: 80px;
}


.dropdown.icon {
  margin-left: 0.5em !important; 
}
