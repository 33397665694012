.search-bar {
  display: inline-flex;
  margin: 0 10px;
}

.pagination-bar {
  float: right;
  font-size: 0.9rem !important;
}

.table-header-right {
  display: inline-flex;
  float: right;
  vertical-align: middle;
  text-align: center;
}

.table-header-right > * {
  vertical-align: middle;
}