.ql-editor {
  min-height: 160px;
}

.ql-tooltip {
  left: 0 !important;
}

.ql-editing {
  left: 0 !important;
}

.quill .ql-container {
  max-height: 500px;
  overflow: auto;
}
